﻿.container {
    margin: 0;
    padding: 0;
    width: 100%
}

/*
.container-fluid {
    margin: 0;
    padding: 0;
}


.row {
    margin-left: 0;
    margin-right: 0;
}
*/


body {
    font-family: 'Oswald';
    font-weight: 300;
    color: #262261;
    font-size: 18px;
}

em {
    color: #ec6956;
    font-style: normal;
}

a {
    color: #7CC1C5;
}

.push {
    margin-bottom: 15px;
}

.push-lg {
    margin-bottom: 30px;
}

.text-default {
    font-family: 'Oswald';
    font-weight: 300;
}

h1, h2, h3, .h1, .h2,.h3, .page-body > p:first-child {
    font-family: 'Zooja';
    font-weight: normal;
    color: #262261;
}


.page-body > p:first-child, .page-body > h1:first-child {
    margin-top: 20px;
    margin-bottom: 30px;
   // line-height: 1.1em; 
}

.page-body > p:first-child {
    font-size: $font-size-h1 * 0.8;
}


.content-item header{
    h1{
            margin-top: 20px;
    margin-bottom: 30px;
    }
}

h1 {
   // font-size: 30px;
}


h1:first-child, h2:first-child, h3:first-child {
    
    margin-top: 0;
}

p {
    margin-bottom: 20px;
}


.lead {
    font-size: 27px;
}

.list-table {
    display: table;
    width: 100%;
}

.list-table li {
    display: table-cell;
    padding: 0 5px;
}

.container-fluid {
    max-width: 1024px;
}

.content-title {
    padding-top: 30px;
    margin-bottom: -10px;
}.layout-footer {
    padding-top: 210px;
    background: transparent url('../Content/Images/bg-scales.png') repeat-x center top;
    .info {
        h4,h5 {
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 10px;
        }
    }
}

.layout-footer a {
    color: #fff;
}

.layout-footer .footer-copy {
    padding: 15px 0 5px 0;
    background-color: #4fc3c6;
    color: #fff;
}


#map {
    height: 400px;
}

@media (min-width: 1024px) {
    .container-fluid {
        margin-left: calc(50% - 512px);
    }
}

@media (max-width: 767px) {
    .text-center-xs {
        text-align: center;
    }

    .content-column {
        padding-bottom: 100px;
    }

    .column-resize {
        img {
            position: relative;
            width: 100%;
        }
    }
}


.zone-navigation .manage-actions{
    display: none;
}