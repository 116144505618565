﻿.blog-posts {
    list-style: none;
    padding: 0;
   // margin-top: 50px;
    
    li {
      //  margin-top: 20px;

        h2 {
            color: #D16957;
        }
    }

    strong {
        font-weight: bold;
        font-size: 1.1em;
    }
}

.blog-post {
    //margin-top: 20px;
}