﻿.navigation {
    background-color: rgba(255, 255, 255, 00);
}

.navigation-menu {
    display: table;
    margin-top: -57px;
    width: 100%;

    ul {
        list-style-type: none;
        margin: 0 -10px;
        padding: 0;
        text-align: center;

        li {
            @media (min-width: $screen-sm-min) {
                margin-left: 10px;
                margin-right: 10px;
            }

            display: inline-block;
            padding: 10px 10px 8px 10px;
            background-size: cover;

            a {
                display: block;
                text-align: center;
                text-decoration: none;
                color: #fff;
                font-size: 1.52em;
                font-family: 'Zooja';
            }
        }
    }
}

    @media (min-width: $screen-sm-min) {
        .navigation-menu {

            ul li {
                min-width: 90px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }

    @media (min-width: $screen-lg-min) {
        .navigation-menu {

            ul li {
                min-width: 120px;
            }
        }
    }

@media (max-width: 1199px) {
    .navigation-menu {

        ul li {
            width: auto;
            padding: 10px 20px 8px 20px;
        }
    }
}

@media (max-width: 1199px) {
    .navigation-menu {
        padding-top: 1rem;
        margin: auto 0;
        width: 100%;

        ul {
            width: 100%;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 5px;
                width: 48%;

                &.current {
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

}

@media (max-width: 455px) {
    .navigation-menu ul li {
        margin-left: 4px;
        width: 100%;
    }
}

@media (min-width: $screen-lg-min) {
    .current {
        cursor: pointer;
        height: 80px;
        position: relative;
        top: -24px;
        margin-bottom: -20px;
    }
}