﻿.fish {
    margin-top: 40px;
}

#mc_embed_signup_scroll {
    margin-bottom: 250px;
    margin-top: 30px;

    .mc-field-group {
        input:not([type=checkbox]) {
            background-color: #E4BA47;
            color: #000;
            height: 60px;
            padding-left: 10px;
            margin-bottom: 20px;
            border: none;
            font-weight: 500;
            border-radius: 0;
            width: 100%;
        }
    }
}

.datefield {
    margin-right: -10px;
}

.datefield .subfield {
    display: inline-block;
    width: 20%;
    margin: 0 10px;

    input {
        text-align: center;
        padding-right: 10px;
    }
}

.mce_inline_error {
    text-align: left;
}

#mce-EMAIL, #mce-FNAME, #mce-LNAME:focus {
    outline: none;
}

.vip_button {
    height: 60px;
    width: 150px;
    background-color: #D16957;
    color: #fff;
    font-weight: 900;
    border: none;
    outline: none;
    margin-left: calc(100% - 150px);
}

#vip_button:hover {
    background-color: #d07b6c;
}

::-webkit-input-placeholder {
    color: #000;
}
::-moz-placeholder {
    color: #000;
}
:-ms-input-placeholder {
    color: #000;
}
:-moz-placeholder {
    color: #000;
}