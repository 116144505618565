﻿.disc-wrapper {
    display: inline-block;
    padding-top: 20px;
}

.square {
    padding: 5px;
    text-align: center;
    color: #fff;
    font-size: 22px;
    display: inline-block;

    max-height: 80px;
    max-width: 80px;

    margin-left: 10px;
}

.square:hover {
    cursor: pointer;
}

.square.square-blue {
    background:transparent url('../content/images/square-blue.png') no-repeat center center;
    background-size: cover;
}
.square.square-red {
    background:transparent url('../content/images/square-red.png') no-repeat center center;
    background-size: cover;
}

.disc {
    padding: 10px;
    text-align: center;
    color: #fff;
    font-size: 22px;
    display: inline-block;

    width: 65px;
    height: 65px;

    &.icon-small {
        padding: 15px;
    }
}

.disc.disc-blue {
    background:transparent url('../content/images/disc-blue-sm.png') no-repeat center center;
    background-size: contain;
}

.disc.disc-aqua {
    background:transparent url('../content/images/disc-aqua-sm.png') no-repeat center center;
    background-size: contain;
}

.disc.disc-pink {
    background:transparent url('../content/images/disc-pink-sm.png') no-repeat center center;
    background-size: contain;
}

.disc.disc-yellow {
    background:transparent url('../content/images/disc-yellow-sm.png') no-repeat center center;
    background-size: contain;
}

.disc-lg {
    padding: 20px 10px;
    text-align: center;
    color: #fff;
    font-size: 36px;
    line-height: 0.9em;

    width: 220px;
    height: 220px;
    display: table;
}

.disc-lg > span {
    display: table-cell;
    vertical-align: middle;
}

.disc-lg .text-default {
    font-weight: 700;
    font-size: 0.6em;
}
.disc-lg .text-default.text-lg {
    font-size: 1em;
}


.disc-lg.disc-blue {
    background:transparent url('../content/images/disc-blue-lg.png') no-repeat center center;
    background-size: cover;
}

    .disc-lg.disc-aqua {
        background: transparent url('../content/images/disc-aqua-lg.png') no-repeat center center;
        background-size: cover;
        width: 210px;
        height: 210px;
    }

.disc-lg.opening-times {
    padding-top: 25px;
    line-height: 0.75em;

    .text-default {
        white-space: pre;

        small {
            font-size: 18.3px;
        }
    }
}

.layout-header,
.lead {
    font-family: 'Zooja';
    font-weight: normal;
}

.layout-header {
    background: transparent url('../Content/Images/bg-wood.jpg') no-repeat center top;
    background-size: cover;
    padding: 20px 0;
    padding-bottom: 80px;
}

.menu-ul {
    padding-left: 0;
}

.menu-link {
    text-decoration: none;
    color: #fff;
}

.menu-link:hover {
    color: #fff;
    text-decoration: none;
}

.list-table {
    width: auto;
    padding-left: 0;
}

@media (max-width: 767px) {
    .square {
        margin-left: 0;
    }
}