﻿.gallery {
    margin-top: 20px;


    div {
        //padding-left: 10px;
        //padding-right: 10px;
        padding-bottom: 10px;
        text-align:center;

        img:hover {
            cursor: pointer;
        }
    }
}